import { Layout } from "./containers/Layout/Layout";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Landing from "./containers/Landing/Landing";
import { Energy } from "./containers/Services/Energy/Energy";
import { CrossZonalCapacity } from "./containers/Services/Energy/SubSections/CrossZonalCapacity";
import { ElectricityMarketAnalysis } from "./containers/Services/Energy/SubSections/ElectricityMarketAnalysis";
import { EuLegislation } from "./containers/Services/Energy/SubSections/EuLegislation";
import { EuropeanElectricityCIM } from "./containers/Services/Energy/SubSections/EuropeanElectricityCIM";
import { Forecasting } from "./containers/Services/Energy/SubSections/Forecasting";
import { Software } from "./containers/Services/Software/Software";
import { OptimizationAndML } from "./containers/Services/OptimizationAndML/OptimizationAndML";
import { ProjectSupport } from "./containers/Services/ProjectSupport/ProjectSupport";
import { AboutUs } from "./containers/AboutUs/AboutUs";
import { Careers } from "./containers/Careers/Careers";
import { ContactUs } from "./containers/ContactUs/ContactUs";
import { News } from "./containers/News/News";
import { PrivacyPolicy } from "./containers/Legal/PrivacyPolicy/PrivacyPolicy";
import { LegalInformation } from "./containers/Legal/LegalInformation/LegalInformation";
import { TermsAndConditions } from "./containers/Legal/TermsAndConditions/TermsAndConditions";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Article from "./containers/News/Article/Article";

// Scrolling on top when changing pages!
export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        document.querySelector(".scroll")?.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <Router>
            <Layout>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/energy" component={Energy} />
                    <Route
                        exact
                        path="/energy/cross-zonal-capacity"
                        component={CrossZonalCapacity}
                    />
                    <Route
                        exact
                        path="/energy/electricity-market-analysis"
                        component={ElectricityMarketAnalysis}
                    />
                    <Route exact path="/energy/eu-legislation" component={EuLegislation} />
                    <Route
                        exact
                        path="/energy/european-electricity-cim"
                        component={EuropeanElectricityCIM}
                    />
                    <Route exact path="/energy/forecasting" component={Forecasting} />

                    <Route exact path="/software" component={Software} />
                    <Route exact path="/optimization-and-ml" component={OptimizationAndML} />
                    <Route exact path="/support" component={ProjectSupport} />
                    <Route exact path="/contact-us" component={ContactUs} />
                    <Route exact path="/about" component={AboutUs} />
                    <Route exact path="/careers" component={Careers} />
                    <Route exact path="/news/:slug" component={Article} />
                    <Route exact path="/news" component={News} />
                    <Route exact path="/privacy" component={PrivacyPolicy} />
                    <Route exact path="/legal" component={LegalInformation} />
                    <Route exact path="/terms" component={TermsAndConditions} />
                    <Route component={Landing} />
                </Switch>
            </Layout>
        </Router>
    );
}

export default App;
