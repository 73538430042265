import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { UpriseCard } from "../../components/UpriseCard/UpriseCard";
import UpriseTechnologies from "../../components/UpriseTechnologies/UpriseTechnologies";
import techAndTools from "../../components/UpriseTechnologies/tech_and_tools.json";
import { ReactComponent as MachineLearningIcon } from "./Icons/Optimization & ML_icon.svg";
import { ReactComponent as ProjectSupportIcon } from "./Icons/Project Support_icon.svg";
import { ReactComponent as SoftwareDevelopmentIcon } from "./Icons/Software Engineering_icon.svg";
import { ReactComponent as EnergyPowerSystemsIcon } from "./Icons/Energy & Power systems_icon.svg";
import { LANDING_POSTS_QUERY } from "../../queries/posts";
import { useQuery } from "@apollo/client";
import { VideoPlayer } from "../../components/VideoPlayer/VideoPlayer";
import { Quotes } from "../../components/Quotes/Quotes";
import quotes from "../../components/Quotes/quotes.json";
import "./Landing.less";

export interface ServicesDetailsOpened {
    energy: boolean;
    software: boolean;
    optimization: boolean;
    support: boolean;
}

export default function Landing() {
    const [servicesOpen, setServicesOpen] = React.useState<ServicesDetailsOpened>({
        energy: false,
        software: false,
        optimization: false,
        support: false,
    });
    const { loading, error, data } = useQuery(LANDING_POSTS_QUERY);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const pickColor = (index: number) => {
        const colors = ["green", "red", "blue"];
        return colors[index % colors.length];
    };

    return (
        <div id="landing" className="container">
            <div className="intro-animation">
                <VideoPlayer
                    src="/vids/uprise_header_animation.mp4"
                    scrollToId="welcome-content-section"
                />
            </div>

            <div className="content">
                <div id="welcome-content-section" className="welcome-content">
                    <h2 className="center">Efficiently Process and Utilize your Data</h2>
                    <h3 className="center">with Reliable, Flexible and Lean Partner</h3>
                    <p>
                        Providing sustainable, innovative and tailor-made software solutions that
                        drive growth and success
                    </p>
                    <p>
                        <strong>
                            Check why 100% of our clients would consider using Uprise services
                            again.
                        </strong>
                    </p>
                </div>

                <section className="gradient-section">
                    <h2 className="center">Services</h2>
                    <div id="what-we-do">
                        <div className="container">
                            <div className="service-category green">
                                <EnergyPowerSystemsIcon className="icon" title="" />
                                <h2>
                                    <Link to="/energy">{"Energy &\nPower Systems"}</Link>
                                </h2>
                                <p>
                                    Uprise provides effective, customized solutions for a wide scope
                                    of issues ranging from power system analysis to market
                                    predictions and utilization.
                                </p>

                                <div
                                    className={`expand ${servicesOpen.energy ? "active" : ""}`}
                                    onClick={e =>
                                        setServicesOpen({
                                            ...servicesOpen,
                                            energy: !servicesOpen.energy,
                                        })
                                    }
                                >
                                    <div className="icon-bar plus"></div>
                                    <div className="icon-bar"></div>
                                </div>
                                <div
                                    className={`service-links ${servicesOpen.energy ? "open" : ""}`}
                                >
                                    <div className="links-content">
                                        <Link to="/energy/cross-zonal-capacity">
                                            X-zonal CC & System security
                                        </Link>
                                        <Link to="/energy/electricity-market-analysis">
                                            Electricity market analysis
                                        </Link>
                                        <Link to="/energy/eu-legislation">EU legislation</Link>
                                        <Link to="/energy/european-electricity-cim">
                                            European electricity CIM expertise
                                        </Link>
                                        <Link to="/energy/forecasting">Forecasting</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="service-category red">
                                <SoftwareDevelopmentIcon className="icon" title="" />
                                <h2>
                                    <Link to="/software">
                                        Software <br />
                                        Engineering
                                    </Link>
                                </h2>
                                <p>
                                    Our experienced developers create new software solutions our
                                    help you with existing ones. From any type of software
                                    application to system integration and website development and
                                    optimization, we have you covered.
                                </p>

                                <div
                                    className={`expand ${servicesOpen.software ? "active" : ""}`}
                                    onClick={e =>
                                        setServicesOpen({
                                            ...servicesOpen,
                                            software: !servicesOpen.software,
                                        })
                                    }
                                >
                                    <div className="icon-bar plus"></div>
                                    <div className="icon-bar"></div>
                                </div>
                                <div
                                    className={`service-links ${
                                        servicesOpen.software ? "open" : ""
                                    }`}
                                >
                                    <div className="links-content">
                                        <Link to="/software#development">Software development</Link>
                                        <Link to="/software#qa">
                                            Quality assurance & software testing
                                        </Link>
                                        <Link to="/software#maintenance">
                                            Maintenance & support
                                        </Link>
                                        <Link to="/software#integration">System integration</Link>
                                        <Link to="/software#transformation">
                                            Digital transformation
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="service-category blue">
                                <MachineLearningIcon className="icon" title="" />
                                <h2>
                                    <Link to="/optimization-and-ml">{`Optimization &\nMachine Learning`}</Link>
                                </h2>
                                <p>
                                    We use the latest technological solutions and sophisticated
                                    algorithms to solve business problems and help them thrive.
                                    These customized solutions save time and boost your business
                                    performance.
                                </p>

                                <div
                                    className={`expand ${
                                        servicesOpen.optimization ? "active" : ""
                                    }`}
                                    onClick={e =>
                                        setServicesOpen({
                                            ...servicesOpen,
                                            optimization: !servicesOpen.optimization,
                                        })
                                    }
                                >
                                    <div className="icon-bar plus"></div>
                                    <div className="icon-bar"></div>
                                </div>

                                <div
                                    className={`service-links ${
                                        servicesOpen.optimization ? "open" : ""
                                    }`}
                                >
                                    <div className="links-content">
                                        <Link to="/optimization-and-ml#optimization">
                                            Optimization
                                        </Link>
                                        <Link to="/optimization-and-ml#ml">Machine learning</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="service-category yellow">
                                <ProjectSupportIcon className="icon" title="" />
                                <h2>
                                    <Link to="/support">
                                        Project <br />
                                        Support
                                    </Link>
                                </h2>
                                <p>
                                    With our agile-driven approach we will turn clever ideas into
                                    working software. Our certified and experienced Project Managers
                                    will make sure to lead, guide and consult you so that your
                                    projects reach their goals.
                                </p>

                                <div
                                    className={`expand ${servicesOpen.support ? "active" : ""}`}
                                    onClick={e =>
                                        setServicesOpen({
                                            ...servicesOpen,
                                            support: !servicesOpen.support,
                                        })
                                    }
                                >
                                    <div className="icon-bar plus"></div>
                                    <div className="icon-bar"></div>
                                </div>

                                <div
                                    className={`service-links ${
                                        servicesOpen.support ? "open" : ""
                                    }`}
                                >
                                    <div className="links-content">
                                        <Link to="/support#project-management">
                                            Project management
                                        </Link>
                                        <Link to="/support#business-analysis">
                                            Business analysis & requirements engineering
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="carousel-container">
                        <UpriseTechnologies title="Our clients" items={techAndTools.clients} />
                    </div>

                    <h2 className="center no-margin">What others say about us</h2>
                    <div className="quotes-container">
                        <Quotes quotes={quotes} />
                    </div>
                </section>

                <section className="gradient-section snippet">
                    <div className="content-section">
                        <div>
                            <h2 className="center">Components that make a difference</h2>
                            <div>
                                Embark on a transformative journey with our company's unrivaled
                                services in web development, seamless integration, advanced linear
                                optimization, and groundbreaking AI applications. Powered by
                                cutting-edge software components, we craft high-performance
                                applications that push the boundaries of innovation. Stay ahead of
                                the curve as we expertly harness the latest technologies, creating
                                tailored solutions for your business's triumph.
                            </div>
                        </div>
                        <div>
                            <img src="/imgs/code-snippet.png" alt="" />
                        </div>
                    </div>

                    <div className="carousel-container">
                        <UpriseTechnologies
                            title="Technology stack"
                            items={techAndTools.technologies}
                        />
                    </div>

                    <div className="content-section iso">
                        <h2>Excellence Assured</h2>
                        <br />
                        <img src="/imgs/bv/bvcer_sansqr-iso 27001 9001 14001.png" alt="ISO certification" />
                    </div>
                </section>

                <section className="gradient-section">
                    <h2 className="center">News and media releases</h2>
                    <div id="articles" className="cards">
                        {data.articles.data &&
                            data.articles.data.map((article: any, index: number) => (
                                <UpriseCard
                                    color={pickColor(index)}
                                    tag={article.attributes.tag}
                                    article={`news/${article.attributes.slug}`}
                                    date={new Date(article.attributes.createdAt)}
                                    title={article.attributes.title}
                                    image={`https://strapi.uprise.hr${article.attributes.image.data.attributes.url}`}
                                    key={article.attributes.slug}
                                />
                            ))}
                    </div>
                    <Link className="see-more" to="/news">
                        See more
                    </Link>

                    <div className="members-container">
                        <h2 className="center">Members of</h2>
                        <div className="members-of">
                            <a
                                href="https://www.amcham.hr/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="/imgs/members/amcham.png"
                                    alt="American Chamber of Commerce"
                                />
                            </a>
                            <a
                                href="https://www.avere.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="/imgs/members/avere.png" alt="Avere" />
                            </a>
                            <a
                                href="https://www.croai.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="/imgs/members/cro_ai.png" alt="Cro AI" />
                            </a>
                            <a
                                href="https://www.cisex.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="/imgs/members/cro_ind_soft.png"
                                    alt="Croatian Independent Software Exporters"
                                />
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
