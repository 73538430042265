import { UpriseHero } from "../../../components/UpriseHero/UpriseHero";
import { useParams } from 'react-router-dom';
import "./Article.less";
import ReactMarkdown from 'react-markdown';
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { ARTICLE_QUERY } from "../../../queries/article";
import { IArticle } from "../../../models/article"
import Moment from "react-moment";

const Article: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const [article, setArticle] = useState<IArticle | null>(null);
    const { loading, error, data } = useQuery(ARTICLE_QUERY, {
        variables: { slug },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (data?.articles?.data?.length > 0) {
            setArticle(data.articles.data[0].attributes);
        }
    }, [data]);

    if (loading) return <div>Loading...</div>;

    if (error) return <div>Error: {error.message}</div>;
    function getContent() {
        if (article?.content?.includes("/uploads")) {
            return article?.content.replaceAll("/uploads", `https://strapi.uprise.hr/uploads`);
        }
        return article?.content;
    }
    return (
        <div id="template-article" className="container article">
            <UpriseHero
                className="article-hero"
                image="/imgs/news.jpg"
                imageM="/imgs/newsm.jpg"
                imageBrightness={80}
            >
                News & Media releases
            </UpriseHero>

            <div className="article-content">
                <div className="article-header">
                    <span><Moment format="MMM DD YYYY">
                        {article?.createdAt}
                    </Moment> I {article?.tag}</span>
                </div>
                <ReactMarkdown className="line-break">{getContent()?.replaceAll(/\n/gi, "&nbsp; \n").replaceAll("{endList}", "\n\n") || ''}</ReactMarkdown>
            </div>
        </div>
    );
}
export default Article;