import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import dateformat from "dateformat";
import "./UpriseNewsArticle.less";

export interface ArticleProps {
    image?: string,
    title: string,
    className?: string,
    date?: Date,
    tag?: string,
    summary?: string
    link?: string
}

export const UpriseNewsArticle: FunctionComponent<ArticleProps> = ({image, title, children, className, date, tag, summary, link, ...props}) => {
    return <div {...props} className={`${className ? className: ''} article-card`}>
        <img className="news-image" src={image} alt="" />
        <div className="article-content">
            {(date || tag) && 
                <span>{date && dateformat(date, "mmm dd, yyyy")}{(date && tag) && " | "}{tag}</span>
            }
            <h2>{title}</h2>
            <p>{summary}</p>
            {link && <>
                <div className="spacer"/>
                <Link to={link}>Read Article</Link>
            </>
            }
        </div>
    </div>
}