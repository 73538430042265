export const TOGGLE_MENU = "TOGGLE_MENU";
export const CLOSE_MENU = "CLOSE_MENU";

export type PageStateActionType = 
    | ReturnType<typeof ToggleMenu>
    | ReturnType<typeof CloseMenu>

export const ToggleMenu = () => {
    return { type: TOGGLE_MENU };
};

export const CloseMenu = () => {
    return { type: CLOSE_MENU }
}