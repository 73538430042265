import { FunctionComponent } from "react";
import { UpriseHero } from "../../components/UpriseHero/UpriseHero";

import { ReactComponent as WhatWeDoIcon } from "./Icons/What we do_icon.svg";

import "./AboutUs.less";
import "../Services/Services.less";
import { WhyUpriseTable } from "../../components/WhyUpriseTable/WhyUpriseTable";

export const AboutUs: FunctionComponent = () => {
    return (
        <div id="about-us" className="container service-container">
            <UpriseHero
                image="/imgs/about-us.jpg"
                imageM="/imgs/about-usm.jpg"
                imageBrightness={80}
            >
                About Us
            </UpriseHero>

            <div className="container">
                <div id="about-what-we-do" className="service-section">
                    <div className="section-title">
                        <WhatWeDoIcon className="icon" title="" />
                        <h2>What we do</h2>
                    </div>

                    <div className="section-content">
                        <p>
                            The core of Uprise activities is software engineering, mostly for the
                            energy and fintech sectors. We are based in Zagreb, Croatia and deliver
                            innovative, cutting-edge software for clients all over Europe. In
                            addition to that, we cover round-the-clock maintenance and support. That
                            kind of dedication and versatility makes for some happy clients.
                        </p>
                        <p>
                            Under our umbrella, we house another brand—Agency.42—dedicated to
                            animation, mobile gaming and design.
                        </p>
                        <p>
                            What has us ticking on a personal level is technical excellence and
                            keeping our minds open to innovation, and those traits project well onto
                            the work we do. We are lucky to have a team of people constantly in
                            search of new ways to grow individually and together - our Uprise crew
                            just naturally wants to evolve. Yes, we are those kinds of developers
                            and creatives. Combine it with dedication to problem-solving and what
                            you get are top-notch solutions that make a difference for our clients.
                            We are an agile crew with great team dynamics and tackle both B2B and
                            B2C projects equally well. Satisfied clients around Europe bear
                            testimony to the quality of our work. Ask us about it.
                        </p>
                    </div>
                </div>
            </div>

            <div className="table-container">
                <WhyUpriseTable />
            </div>
        </div>
    );
};
