import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { CustomCookies } from './components/CustomCookie/CustomCookie';
import './index.less';
import { ApolloProvider } from '@apollo/client';
import client from './client';

const store = configureStore();


ReactDOM.render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <App />
            <CustomCookies />
        </ApolloProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
