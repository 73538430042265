import { FunctionComponent } from "react";
import { Carousel } from "react-responsive-carousel";
import { UpriseHero } from "../../../../components/UpriseHero/UpriseHero";
import { ReactComponent as LeftArrow } from "./left arrow.svg";
import { ReactComponent as RightArrow } from "./right arrow.svg";

import "../../Services.less";
import "./SubSections.less";

export const ElectricityMarketAnalysis: FunctionComponent = () => {
    return (
        <div id="electricity-market" className="energy-container">
            <UpriseHero image="/imgs/analytics.jpg" half={true} imageBrightness={100}>
                Electricity market analysis
            </UpriseHero>

            <div className="energy-section">
                <p>
                    Energy transition implies the growing need for rapid and agile change of the
                    traditional electricity market while maintaining system security and system
                    operation costs. The electricity market should create incentives able to tackle
                    volatile renewable sources, integrate large numbers of participants/assets,
                    increase power system flexibility and integrate new market mechanisms.
                </p>
                <p>
                    The ultimate European goal of achieving climate neutrality by 2050, focuses
                    ontransitioning towards greenhouse gas neutrality of the energy sector. This
                    path creates opportunities for existing and future market participants and
                    system operators in the electricity market. The twin green and digital
                    transitions foresee greater involvement of the public in the electricity market,
                    as well as an increase in the number of prosumers/assets participating in the
                    market.
                </p>
                <p>
                    At Uprise, not only do we closely follow the trends, but also actively
                    participate in designing solutions for the new European power system vision.
                </p>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>AREAS OF EXPERTISE</h3>

                <div className="expertise">
                    <div className="expertise-item">
                        <span className="ordinal">1</span>
                        <div className="expertise-content">
                            <div>
                                <b>System adequacy</b>
                            </div>
                            <p>
                                The ability to predict energy scarcity hours in certain countries
                                represents one of the crucial steps in assuring secure operation of
                                the European power system. It offers an opportunity for TSOs to
                                prepare countermeasures in terms of energy trading and reserve
                                activation in order to mitigate any potential risk if the scarcity
                                occurs. At Uprise we have supported the rollout process of
                                cross-regional adequacy tool in terms of requirements engineering,
                                solution design and testing.
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">2</span>
                        <div className="expertise-content">
                            <div>
                                <b>Ancillary services</b>
                            </div>
                            <p>
                                With backgrounds in TSO and the energy industry, our experts know
                                how to properly dimension and procure balancing reserves. Our
                                experience here ranges from documentation of prequalification
                                procedures to optimization of balancing reserves.
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">3</span>
                        <div className="expertise-content">
                            <div>
                                <b>Cross-border participation in capacity mechanisms</b>
                            </div>
                            <p>
                                Capacity allocation from another country in order to ensure local
                                power system adequacy became possible after the implementation of
                                “Cross-border participation in capacity mechanisms” methodologies.
                                In this sense, Uprise made a significant contribution to the
                                implementation of Capacity Registry Tool as the central platform
                                where data about capacity providers is exchanged.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>PRODUCTS AND CASE STUDIES</h3>

                <div className="energy-carousel">
                    <Carousel
                        autoPlay
                        infiniteLoop
                        showThumbs={false}
                        showStatus={false}
                        interval={5000}
                        renderArrowPrev={(clickHandler) => (
                            <LeftArrow
                                onClick={clickHandler}
                                aria-label="previous slide / item"
                                className="arrow left"
                                title=""
                            />
                        )}
                        renderArrowNext={(clickHandler) => (
                            <RightArrow
                                onClick={clickHandler}
                                aria-label="next slide / item"
                                className="arrow right"
                                title=""
                            />
                        )}
                    >
                        <div className="carousel-panel">
                            <h3>Adequacy tool</h3>
                            <p>
                                Project consultancy and support for setting up of cross-regional
                                adequacy tool designed to predict energy scarcity hours in Europe.
                            </p>
                        </div>
                        <div className="carousel-panel">
                            <h3>Capacity registry tool</h3>
                            <p>
                                Project consultancy and support for setting up a central registry of
                                capacity providers participating in foreign capacity mechanisms.
                            </p>
                        </div>
                    </Carousel>
                </div>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>KEYWORDS</h3>
                <br />
                <div className="keywords">
                    Adequacy, Capacity Registry, aFRR, mFRR, FCR, AGC, Capacity Mechanism
                </div>
            </div>
        </div>
    );
};
