import { FunctionComponent } from "react";

import { UpriseHero } from "../../components/UpriseHero/UpriseHero";
import { ReactComponent as CareersIcon } from "./Icons/Careers_icon.svg";

import "./Careers.less";
import "../Services/Services";

export const Careers: FunctionComponent = () => {
    return (
        <div id="career" className="container service-container">
            <UpriseHero image="/imgs/careers.jpg" imageM="/imgs/careersm.jpg" imageBrightness={80}>
                Careers
            </UpriseHero>

            <h2>Open positions</h2>


            <div className="container">

                <div className="service-section">
                    <div className="section-title">
                        <CareersIcon className="icon" title="" />
                        <h2>Mid or Senior-Level Software Engineer</h2>
                    </div>
                    <div className="section-content">
                        <div className="small-space">
                            We are a Zagreb-based young team of hard-working and enthusiastic experts located right in the city center in the beautiful new offices, dedicated to providing custom software development and other product lifecycle services, which includes both internal and external projects. We are a leader in energy transition using optimization and machine learning to facilitate green economy.<br />                        </div>
                        <br />We are big supporters of proactiveness and are always open to support innovative ideas. <br /><br />
                        If you're interested in end-to-end development and want to work with cutting-edge technologies, come on board. <br /><br />
                        <p></p>
                        <div className="small-space">
                            <strong>Your role would consist of:</strong>
                        </div>
                        <div className="small-space">
                            Backend development using .NET Core  
                        </div>
                        <div className="small-space">
                            Frontend development using React 
                        </div>
                        <div className="small-space">
                            API development using REST API 
                        </div>
                        <div className="small-space">
                            Write and maintain automated test suites 
                        </div>
                        <div className="small-space">
                            Plan tasks and activities with Project Manager and teammates  
                        </div>
                        <div className="small-space">
                            Collaborate with Project Lead and other developers 
                        </div>
                        <div className="small-space">
                            Have regular professional development programs  
                        </div>                       
                        <p></p>
                        <div className="small-space">
                            <strong>Your profile:</strong>
                        </div>
                        <div className="small-space">
                            3+ years of experience 
                        </div>
                        <div className="small-space">Masters in Computer Science, Mathematics, Statistics, or another related field </div>
                        <div className="small-space">Experience with version control in Git/Gitlab </div>
                        <div className="small-space">
                            Advanced knowledge of C# .NET, preferably .NET Core 
                        </div>
                        <div className="small-space">Advanced knowledge of Javascript and/or TypeScript, preferably ReactJS </div>
                        <div className="small-space">Advanced knowledge of HTML, CSS and SOAP/REST web services </div>
                        <div className="small-space">Knowledge of Docker and Orchestrator systems is a plus  </div>
                        <div className="small-space">Good knowledge of data structure design and SQL database management system </div>
                        <div className="small-space">Passion to experiment with new technologies from frontend, backend and ML ecosystem </div>
                        <div className="small-space">Excellent command of Croatian and English  </div>
                        <div className="small-space">Team player, proactive, thoughtful and efficient problem solver </div>
                        <div className="small-space">You are able to perform independently or in a small team </div>
                        <div className="small-space">A pragmatic approach, biased to simplicity, where you most care about achieving impact </div>
                        <p></p>
                        <div className="small-space">
                            <strong>Why choose Uprise?</strong>
                        </div>
                        <div className="small-space">Professional development - Challenging tasks, opportunity to work on different projects and express and implement your ideas.</div>
                        <div className="small-space">
                            Culture - Team spirit, passion, creativity, flexibility and proactiveness are the drivers of our company.
                        </div>
                        <div className="small-space">
                            Salary & Perks - Competitive salary, flexible working hours, team buildings, after work sports.
                        </div>
                        <p></p>
                        <div className="small-space">
                            Send your CV and a short motivational letter to <a href="mailto:careers.dev@uprise.hr">careers.dev@uprise.hr</a>
                            {" "}with the name of the position in the subject of the email.
                        </div>
                    </div>
                </div>

                <hr className="divider" />

                <div className="service-section">
                    <div className="section-title">
                        <CareersIcon className="icon" title="" />
                        <h2>Business analyst</h2>
                    </div>
                    <div className="section-content">
                        <div className="small-space">
                            Uprise d.o.o. is a young Croatian-based and fast-growing IT company, working for the energy and fintech sector for partners in the EU and around the world with cutting-edge technologies.
                        </div>
                        <p></p>
                        <div className="small-space">
                            <strong>Your role would consist of:</strong>
                        </div>
                        <div className="small-space">
                            Gathering and analyzing requirements from existing and target services
                        </div>
                        <div className="small-space">
                            Performing gap analysis with services that are already ongoing or in development
                        </div>
                        <div className="small-space">
                            Constructing workflow charts and diagrams
                        </div>
                        <div className="small-space">
                            Studying relevant process design and interactions
                        </div>
                        <div className="small-space">
                            Documenting business requirements and writing detailed specifications
                        </div>
                        <div className="small-space">
                            Preparing technical reports by collecting, analyzing and summarizing gathered information
                        </div>
                        <div className="small-space">
                            Performing monthly reviews and analyses of current processes and their development by interacting with respective Project team members
                        </div>
                        <div className="small-space">
                            Suggesting changes to Project management using analytics to support the recommendations
                        </div>
                        <div className="small-space">
                            Creating informative, actionable and repeatable reporting that highlights relevant business trends and opportunities for improvement
                        </div>
                        <p></p>
                        <div className="small-space">
                            <strong>Your profile:</strong>
                        </div>
                        <div className="small-space">
                            Bachelor's Degree in appropriate field of study (Power engineering, Software engineering)
                        </div>
                        <div className="small-space">Knowledge of business process modelling (e.g. Business Process Model and Notation - BPMN) and experience in delivery of business and functional requirements for software development is considered beneficial</div>
                        <div className="small-space">Ability to impact operations and effect change without being confrontational</div>
                        <div className="small-space">
                            Detail-oriented, analytical and inquisitive
                        </div>
                        <div className="small-space">Ability to work independently and with others</div>
                        <div className="small-space">Well organized with strong time-management skills</div>
                        <p></p>
                        <div className="small-space">
                            <strong>Why choose Uprise?</strong>
                        </div>
                        <div className="small-space">Professional development - Challenging tasks, opportunity to work on different projects and express and implement your ideas.</div>
                        <div className="small-space">
                            Culture - Team spirit, passion, creativity, flexibility and proactiveness are the drivers of our company.
                        </div>
                        <div className="small-space">
                            Salary & Perks - Competitive salary, flexible working hours, team buildings, after work sports.
                        </div>
                        <p></p>
                        <div className="small-space">
                            Send your CV and a short motivational letter to <a href="mailto:careers.dev@uprise.hr">careers.dev@uprise.hr</a>
                            {" "}with the name of the position in the subject of the email.
                        </div>
                    </div>
                </div>

                <hr className="divider" />

                <div className="service-section">
                    <div className="section-title">
                        <CareersIcon className="icon" title="" />
                        <h2>3D Artist/Animator/Graphic Designer</h2>
                    </div>
                    <div className="section-content">
                        <div className="small-space">
                            Uprise is a Zagreb-based fast-growing IT company dedicated to custom software development. The company has provided services for a range of industries including Energy & Renewables, Construction, and FinTech. In addition to development, we also offer design, extended reality and animation services.<br />
                            We are now looking for a creative individual to expand our team in accordance with our current business development agenda.
                        </div>
                        <p></p>
                        <div className="small-space">
                            <strong>Your role would consist of:</strong>
                        </div>
                        <div className="small-space">
                            Creating 3D models, textures, animations and visual effects.
                        </div>
                        <div className="small-space">
                            Creating logo design, icons and simple illustrations.
                        </div>
                        <div className="small-space">
                            Creating layouts for websites and apps.
                        </div>
                        <div className="small-space">
                            Creating storyboards and environment for cartoons, games, and other visual effects.
                        </div>
                        <div className="small-space">
                            Lighting, rendering and rigging.
                        </div>
                        <div className="small-space">
                            Collaborate closely with other creative team members, directors and clients, participate in team meetings.
                        </div>
                        <div className="small-space">
                            Understand project requirements, provide and conceptualizing creative ideas.
                        </div>
                        <div className="small-space">
                            Reviewing 3D arts and recommending improvements.
                        </div>
                        <p></p>
                        <div className="small-space">
                            <strong>Your profile:</strong>
                        </div>
                        <div className="small-space">
                            Master's degree in design, art academy or a related field.
                        </div>
                        <div className="small-space">Professional experience as a 3D Artist, Animator or Graphic Designer, preferably in animation industry.</div>
                        <div className="small-space">Excellent creativity and artistic skills.</div>
                        <div className="small-space">
                            Proficiency in 3D modeling, texturing, shading, lighting and rendering.
                        </div>
                        <div className="small-space">Strong working knowledge of industry-standard software, including Blender, Adobe Creative Suite (Adobe Illustrator, Adobe Photoshop, Adobe After Effects)</div>
                        <div className="small-space">Ability to work independently and in team-oriented environment.</div>
                        <p></p>
                        <div className="small-space">
                            <strong>Why choose Uprise?</strong>
                        </div>
                        <div className="small-space">Professional development - Challenging tasks, opportunity to work on different projects and express and implement your ideas.</div>
                        <div className="small-space">
                            Culture - Team spirit, passion, creativity, flexibility and proactiveness are the drivers of our company.
                        </div>
                        <div className="small-space">
                            Salary & Perks - Competitive salary, flexible working hours, team buildings, after work sports.
                        </div>
                        <p></p>
                        <div className="small-space">
                            Send your CV and a short motivational letter to <a href="mailto:careers.creative@uprise.hr">careers.creative@uprise.hr</a>
                            {" "}with the name of the position in the subject of the email.
                        </div>
                    </div>
                </div>

                <hr className="divider" />

                <div className="service-section">
                    <div className="section-title">
                        <CareersIcon className="icon" title="" />
                        <h2>Open applications</h2>
                    </div>
                    <div className="section-content">
                        <p>
                            Don't see your position here? Send us an open application with your CV and a short motivational
                            letter to <a href="mailto:careers@uprise.hr">careers@uprise.hr</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
