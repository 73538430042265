import React from 'react';
import { HashLink as Link } from "react-router-hash-link";

import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store/configureStore";
import { CloseMenu, ToggleMenu } from "../../actions/pageActions";

import './Menu.less';
import menuTree from './menuTree.json';

function Menu() {
    const [ expanded, setExpanded ] = React.useState<string>("");
    
    const menuOpen = useSelector<AppState>(state => state.pageState.menuOpen);
    const dispatch = useDispatch();
    const toggle = () => {
        dispatch(ToggleMenu());
    }
    
    // On window resize close menu!
    React.useEffect(() => {
        function handleResize() {
            if (menuOpen) {
                dispatch(CloseMenu());
            }
            window.removeEventListener('resize', handleResize);
        }
        window.addEventListener('resize', handleResize);
    });

    function infoExpanded() {
        return (
            <div id="info-expanded">
                <div className="info-section">
                    <div>Uprise.d.o.o.</div>
                    <div>OIB 54026168821</div>
                    <div>VAT HR 54026168821</div>
                </div>
                <div className="info-section">
                    <div>info@uprise.hr</div>
                    <div>+385 98 9669 587</div>
                </div>
                <div className="info-section">
                    <div>Petrova 15</div>
                    <div>10 000, Zagreb</div>
                    <div>Croatia</div>
                </div>
                <div className="info-section">
                    <div>2018 Uprise</div>
                    <div>All rights reserved</div>
                    <div>Management System Policy</div>
                </div>
            </div>
        );
    }

    function servicesExpanded(services: any) {
        return (
            <div id="services-expanded">
                {services.map((service: any) => {
                    return <Link key={service.title} to={service.link} className="services-link" onClick={toggle}>
                        {service.title}
                    </Link>
                })}
            </div>
        )
    }

    return (
        <div id="menu-container" className={`${menuOpen ? "menu-open": ""}`}>
            {menuTree.map(item => {
                return <div key={item.key} className={`menu-item-container ${item.key === expanded ? "expanded" : ""}`}> 
                    {item.link &&
                        <Link to={item.link} className="menu-link" onClick={toggle}>
                            <div className="menu-item">
                                <p>{item.title}</p>
                            </div>
                        </Link>
                    }
                    {!item.link &&
                        <div className="menu-item" onClick={e => item.key === expanded ? setExpanded("") : setExpanded(item.key)}>
                            <p>{item.title}</p>

                            <div className={`expand ${expanded === item.key ? "active" : ""}`}>
                                <div className="icon-bar plus"></div>
                                <div className="icon-bar"></div>
                            </div>
                        </div>
                    }

                    {item.key === "info" && 
                        infoExpanded()
                    }
                    {item.key === "services" &&
                        servicesExpanded(item.elements)
                    }
                </div>
            })}
            <div className="menu-item-container"></div>
        </div>
    );
}

export default Menu;