import React from "react";
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as LeftArrow } from "./left arrow_tools&technologies.svg";
import { ReactComponent as RightArrow } from "./right arrow_tools&technologies.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./UpriseTechnologies.less";
import { Link } from "react-router-dom";

export interface ItemType {
    name: string;
    icon: string;
    link?: string;
}

export interface TechnologiesProps {
    title: string;
    items: Array<Array<ItemType>>; // Array of arrays means each panel of carousel has multiple grouped elements.
}

function UpriseTechnologies(props: TechnologiesProps) {
    const item = (tech: ItemType) => {
        return (
            <div key={tech.name} className={tech.link ? "" : "tech-item"}>
                <img src={tech.icon} alt={`${tech.name} icon`} />
                <h4>{tech.name}</h4>
            </div>
        );
    };

    return (
        <div className="technologies">
            <h2>{props.title}</h2>

            <Carousel
                autoPlay={props.items.length > 1}
                infiniteLoop={props.items.length > 1}
                showThumbs={false}
                showStatus={false}
                showIndicators={props.items.length > 1}
                interval={10000}
                renderArrowPrev={clickHandler =>
                    props.items.length > 1 && (
                        <LeftArrow
                            onClick={clickHandler}
                            aria-label="previous slide / item"
                            className="arrow left"
                            title=""
                        />
                    )
                }
                renderArrowNext={clickHandler =>
                    props.items.length > 1 && (
                        <RightArrow
                            onClick={clickHandler}
                            aria-label="next slide / item"
                            className="arrow right"
                            title=""
                        />
                    )
                }
            >
                {props.items.map((group, i) => {
                    return (
                        <div key={props.title + i} className="tech-items">
                            {group.map(tech => {
                                if (tech.link) {
                                    return (
                                        <a
                                            key={tech.link}
                                            href={tech.link}
                                            className="tech-item"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {item(tech)}
                                        </a>
                                    );
                                } else {
                                    return item(tech);
                                }
                            })}
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
}

export default UpriseTechnologies;
