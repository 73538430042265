import "./UpriseToast.less";

export interface ToastProps {
    toastActive: boolean;
    message: string; 
}

export const UpriseToast = function (props: ToastProps) {
    return <div className={`uprise-toast ${props.toastActive ? "show" : ""}`}>
        {props.message}
    </div>
}
