import { useEffect, useState } from "react";
import { AppState } from "../../store/configureStore";
import { useSelector, useDispatch } from "react-redux";
import { CloseMenu } from "../../actions/pageActions";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as LogoMobile } from "./logo_mobile.svg";
import { ReactComponent as ContactLogo } from "./Message_icon.svg";
import { HashLink as Link } from "react-router-hash-link";
import navigationTree from "./navigationTree.json";
import MenuButton from "../MenuButton/MenuButton";
import "./UpriseHeader.less";

interface LinkInfo {
    link: string;
    title: string;
}

export default function UpriseHeader() {
    const [open, setOpen] = useState<boolean>(false);
    const [previous, setPrevious] = useState<string | undefined>(undefined);
    const [active, setActive] = useState<string | undefined>(undefined);
    const [subActive, setSubActive] = useState<string | undefined>(undefined);
    const [subHover, setSubHover] = useState<string | undefined>(undefined);
    const [otherLinks, setOtherLinks] = useState<LinkInfo[]>([]);

    const menuOpen = useSelector<AppState>(state => state.pageState.menuOpen);
    const dispatch = useDispatch();
    const closeMenu = () => {
        dispatch(CloseMenu());
    };

    useEffect(() => {
        if (subHover) {
            setSubActive(subHover);
            let subItem = navigationTree
                .find(i => i.key === active)!
                .children!.find(c => c.key === subHover)!;
            if (subItem.links) {
                setOtherLinks([...subItem.links]);
            } else {
                setOtherLinks([]);
            }
        }
    }, [subHover]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (open && subActive) {
            setSubHover(subActive);
        }
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleActive = (key: string, close: boolean = false) => {
        setPrevious(active);
        if (active === key && open) {
            setOpen(false);
        } else {
            if (!close) {
                setOpen(true);
            } else {
                setOpen(false);
            }
            setActive(key);
        }
        setOtherLinks([]);
    };

    const toggleSubActive = (key: string) => {
        setSubActive(key);
        closeHeader();
    };

    const closeHeader = () => {
        setOpen(false);
    };

    const clearActiveAndClose = () => {
        closeHeader();
        setPrevious(active);
        setActive(undefined);
    };

    const generateSubnav = () => {
        let key = active;
        if (!key || navigationTree.find(i => i.key === key)!.isLink) {
            key = previous;
        }
        if (key) {
            let item = navigationTree.find(i => i.key === key)!;

            if (!item.isLink) {
                let elements = item.children!;

                return (
                    <div className="subnav">
                        {elements.map(e => {
                            let className =
                                "subnav-item noselect" + (subActive === e.key ? " active" : "");

                            if (e.outgoing) {
                                return (
                                    <a
                                        target="_blank"
                                        href={e.link}
                                        rel="noopener noreferrer"
                                        className={className}
                                    >
                                        {e.title}
                                    </a>
                                );
                            } else {
                                return (
                                    <Link
                                        onClick={toggleSubActive.bind(null, e.key)}
                                        onMouseEnter={setSubHover.bind(null, e.key)}
                                        key={e.key}
                                        className={className}
                                        to={`/${e.key}`}
                                    >
                                        {e.title}
                                    </Link>
                                );
                            }
                        })}
                    </div>
                );
            }
        }
        return null;
    };

    const generateLinks = () => {
        let className = "links-container" + (otherLinks.length ? "" : " hidden");
        return (
            <div className={className}>
                {otherLinks.map(l => (
                    <Link
                        key={l.link}
                        onClick={toggleSubActive.bind(null, subHover!)}
                        className="subnav-item noselect"
                        to={l.link}
                    >
                        {l.title}
                    </Link>
                ))}
            </div>
        );
    };

    return (
        <header>
            <div className={`header-shadow glass ${menuOpen ? "menu-open" : ""}`} />
            <div className="container">
                <Link to="/" onClick={clearActiveAndClose}>
                    <Logo id="logo" title="" />
                    <LogoMobile id="logo-mobile" onClick={closeMenu} />
                </Link>
                <nav onMouseLeave={closeHeader} className={open ? "active" : undefined}>
                    {navigationTree.map(item => {
                        let className =
                            "nav-item noselect" + (active === item.key ? " active" : "");
                        return item.isLink ? (
                            <Link
                                onClick={toggleActive.bind(null, item.key, true)}
                                key={item.key}
                                className={className}
                                to={item.key}
                            >
                                {item.title}
                            </Link>
                        ) : (
                            <div
                                onClick={toggleActive.bind(null, item.key, false)}
                                key={item.key}
                                className={className}
                            >
                                {item.title}
                            </div>
                        );
                    })}
                    <div
                        className={`subnav-container glass ${
                            active === "products" ? "products-active" : " "
                        }`}
                    >
                        {generateSubnav()}
                        {generateLinks()}
                    </div>
                </nav>
                <Link id="contact-link" to="/contact-us">
                    <ContactLogo className="contact-icon" title="" />
                </Link>

                <MenuButton />
            </div>
        </header>
    );
}
