import { FunctionComponent, useEffect, useState } from "react";

import "./CustomCookie.less";

declare function googleAnalytics(): void;

export const CustomCookies: FunctionComponent = () => {
    const [bannerVisible, setBannerVisible] = useState<boolean>(true);

    useEffect(() => {
        // Method for getting cookie value: https://developer.mozilla.org/en-US/docs/Web/API/document/cookie#example_2_get_a_sample_cookie_named_test2
        let cookieConsent = document.cookie
            ?.split("; ")
            ?.find((row) => row.startsWith("cookieConsent="))
            ?.split("=")[1];
        // Cookie consent was revoked...
        if (cookieConsent === "revoke") setBannerVisible(true);
        // Cookie consent was granted...
        else if (cookieConsent === "grant") setBannerVisible(false);
    }, []);

    // If cookies are either accepted or declined we should remove the cookie-banner!

    function cookiesAccepted() {
        // Set google analytics cookies.
        googleAnalytics();
        // Setting up cookie-consent so that we know not to show consent-banner again.
        document.cookie = `cookieConsent=grant;Max-Age=${60 * 60 * 24 * 30 * 24};`; // Max-Age needs to be in seconds. Cookie is valid for 2 years.
        // Banner no longer visible.
        setBannerVisible(false);
    }

    function cookiesDeclined() {
        // Setting up cookie-consent so that we know not to show consent-banner again.
        document.cookie = `cookieConsent=revoke;Max-Age=${60 * 60 * 24 * 30 * 24}`; // Max-Age needs to be in seconds. Cookie is valid for 2 years.
        // Banner no longer visible.
        setBannerVisible(false);
    }

    return (
        <div id="custom-cookie-banner" className={`cookie ${!bannerVisible ? "hide" : ""}`}>
            <p>This website uses cookies to ensure you get the best experience on our website.</p>
            <div id="cookie-buttons">
                <button id="accept-cookies" onClick={cookiesAccepted}>
                    Accept
                </button>
                <button id="decline-cookies" onClick={cookiesDeclined}>
                    Decline
                </button>
            </div>
        </div>
    );
};
