import { FunctionComponent } from "react";
import { Tagline } from "../../../components/Tagline/Tagline";

import "../Legal.less";

export const TermsAndConditions: FunctionComponent = () => {
    return (
        <div id="terms" className="legal-section">
            <div className="header">
                <Tagline>Terms & conditions</Tagline>
            </div>

            <div className="content">
                <h3>This page is currently under construction.</h3>
            </div>
        </div>
    );
};
