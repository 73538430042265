import { FunctionComponent, ReactNode } from 'react';
import { Link } from "react-router-dom";
import './Tagline.less';

export interface TaglineProps  {
    children: ReactNode;
    learnMore?: string;
    className?: string;
}

export const Tagline:FunctionComponent<TaglineProps> = ({children, learnMore = undefined, className, ...props} : TaglineProps) => {
    return <div {...props} className={`${className ? className: ''} tagline`}>
        <h2>{children}</h2>
        {learnMore && <Link to={learnMore} className="learn-more-btn">Learn more</Link>}
    </div>
}