import { FunctionComponent } from "react";
import { UpriseHero } from "../../../components/UpriseHero/UpriseHero";
import UpriseTechnologies from "../../../components/UpriseTechnologies/UpriseTechnologies";
import techsAndTools from "../../../components/UpriseTechnologies/tech_and_tools.json";
import { WhyUpriseTable } from "../../../components/WhyUpriseTable/WhyUpriseTable";
import { ReactComponent as BusinessAndReqirementsIcon } from "./Icons/BA and requirements_icon.svg";
import { ReactComponent as ProjectManagementIcon } from "./Icons/Project management_icon.svg";
import "../Services.less";
import { Tagline } from "../../../components/Tagline/Tagline";

export const ProjectSupport: FunctionComponent = () => {
    return (
        <div id="project-support" className="container service-container">
            <UpriseHero image="/imgs/support.jpg" imageM="/imgs/supportm.jpg" imageBrightness={80}>
                Project support
            </UpriseHero>

            <h2>We never lose sight of your goals.</h2>

            <div className="container">
                <div id="project-management" className="service-section">
                    <div className="section-title">
                        <ProjectManagementIcon className="icon" title="" />
                        <h2>Project Management</h2>
                    </div>
                    <div className="section-content">
                        <p>
                            We have IPMA certified Project Managers who will plan and oversee the
                            project from start to finish. They designate project resources, prepare
                            budgets, monitor progress and keep stakeholders informed throughout the
                            whole project.
                        </p>
                        <p>
                            A Project Manager is assigned to each project and will be the client’s
                            point of contact from start to finish. Through their experience and
                            education, they are equipped to face any challenges that may arise. Each
                            of our project managers exercise expertise and competence to build trust
                            and ensure quality and satisfaction.
                        </p>
                    </div>
                    <Tagline className="section-tagline">
                        Relax knowing you are in good hands.
                    </Tagline>
                </div>
                <hr className="divider" />
                <div id="business-analysis" className="service-section">
                    <div className="section-title">
                        <BusinessAndReqirementsIcon className="icon" title="" />
                        <h2>Business analysis & requirements engineering</h2>
                    </div>
                    <div className="section-content">
                        <p>
                            Using their strong technology, analytical and communication skills, our
                            BA experts analyse your business problem, deriving business needs and
                            requirements and then providing solutions to those needs. In constant
                            communication with the client, the selected IT solution(s) and its
                            detailed requirements are then defined, documented, validated and
                            managed.
                        </p>
                    </div>
                    <Tagline className="section-tagline">
                        Your success is our responsibility.
                    </Tagline>
                </div>

                <hr className="divider" />

                <div className="tech-container">
                    <UpriseTechnologies
                        title="Technologies we use"
                        items={techsAndTools.technologies}
                    />
                </div>
                <hr className="divider" />
                <div className="table-container">
                    <WhyUpriseTable />
                </div>
                <hr className="divider" />
                <div className="tech-container">
                    <UpriseTechnologies title="Tools we use" items={techsAndTools.tools} />
                </div>
            </div>
        </div>
    );
};
