import { FunctionComponent } from "react";
import { Carousel } from "react-responsive-carousel";
import { UpriseHero } from "../../../../components/UpriseHero/UpriseHero";
import { ReactComponent as LeftArrow } from "./left arrow.svg";
import { ReactComponent as RightArrow } from "./right arrow.svg";

import "../../Services.less";
import "./SubSections.less";

export const EuLegislation: FunctionComponent = () => {
    return (
        <div id="eu-legislation" className="energy-container">
            <UpriseHero image="/imgs/legislation.jpg" half={true} imageBrightness={100}>
                EU legislation
            </UpriseHero>

            <div className="energy-section">
                <p>
                    EU Network codes, and consequently methodologies derived from them, represent a
                    set of rules based on the EU Commission Regulations with the goal of
                    facilitating harmonisation, integration and efficiency of the European
                    electricity market.
                </p>
                <p>
                    At Uprise we understand and anticipate their effect on markets and implications
                    on system security, as well as their relation to technical standards,and
                    national regulations. However, our expertise reaches beyond pure theoretical
                    understanding, as our experts have actively participated in methodologies’
                    drafting, business solution design and development of solutions.
                </p>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>AREAS OF EXPERTISE</h3>

                <div className="expertise">
                    <div className="expertise-item">
                        <span className="ordinal">1</span>
                        <div className="expertise-content">
                            <div>
                                <b>Connection Network Codes</b>
                            </div>
                            <p>
                                Connection codes consist of three codes related to criteria that
                                need to be met in order to integrate new elements in the power
                                system grid. Those include requirements for generators, high voltage
                                direct current connections, as well as consumers and demand side
                                response participants. At Uprise, we have a deep understanding of
                                these codes demonstrated by the fact that we have performed
                                comparative analysis against IEC, CENELEC and EN standards.
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">2</span>
                        <div className="expertise-content">
                            <div>
                                <b>Operations Network Codes</b>
                            </div>
                            <p>
                                Operation codes define how TSOs manage their power grid in normal
                                operation, as well as in situations when an incident in the system
                                occurs. These Network codes are referred to as Guideline on
                                electricity transmission system operation (SOGL) and Network code on
                                electricity emergency and restoration. Our experts have experience
                                in creating regional level methodologies derived from the Network
                                code requirements, as well as in implementing requirements in an
                                actual system operation.
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">3</span>
                        <div className="expertise-content">
                            <div>
                                <b>Market Network Codes</b>
                            </div>
                            <p>
                                Market codes define rules for energy market participation and energy
                                capacity related calculations. These consist of a Guideline on
                                electricity balancing (EBGL) that defines sharing of capacity
                                resources among different countries; Guideline on capacity
                                allocation and congestion management (CACM) that defines methods for
                                calculation of capacities that market participants can use on cross
                                border lines; and Guideline on forward capacity allocation (FCA)
                                that deals with long term electricity markets. At Uprise we are
                                especially proud of having developed solutions related to capacity
                                allocation and congestion management guidelines, which are
                                implemented and running as a service on a daily basis.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>PRODUCTS AND CASE STUDIES</h3>

                <div className="energy-carousel">
                    <Carousel
                        autoPlay
                        infiniteLoop
                        showThumbs={false}
                        showStatus={false}
                        interval={5000}
                        renderArrowPrev={(clickHandler) => (
                            <LeftArrow
                                onClick={clickHandler}
                                aria-label="previous slide / item"
                                className="arrow left"
                                title=""
                            />
                        )}
                        renderArrowNext={(clickHandler) => (
                            <RightArrow
                                onClick={clickHandler}
                                aria-label="next slide / item"
                                className="arrow right"
                                title=""
                            />
                        )}
                    >
                        <div className="carousel-panel">
                            <h3>Operational data gathering and reporting</h3>
                            <p>
                                Web-based tool developed for the purpose of annual operational data
                                gathering and automatic report generation in line with legal
                                requirements.
                            </p>
                        </div>
                        <div className="carousel-panel">
                            <h3>Comparative analysis - codes vs standards</h3>
                            <p>
                                Consultancy provided in terms of gap identification between
                                technical standards and connection network codes, with the final
                                product in the form of a technical report and a proposal for gap
                                resolving.
                            </p>
                        </div>
                    </Carousel>
                </div>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>KEYWORDS</h3>
                <br />
                <div className="keywords">
                    CACM, SOGL, CEP. RfG, HVDC, DCC, IEC, CLC, EN, CSA, ROSC, CC
                </div>
            </div>
        </div>
    );
};
