import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { UpriseHero } from "../../../components/UpriseHero/UpriseHero";
import UpriseTechnologies from "../../../components/UpriseTechnologies/UpriseTechnologies";
import techsAndTools from "../../../components/UpriseTechnologies/tech_and_tools.json";
import { WhyUpriseTable } from "../../../components/WhyUpriseTable/WhyUpriseTable";

import { ReactComponent as CrossBorderIcon } from "./Icons/Cross border capacity calculation_icon.svg";
import { ReactComponent as ElectricityMarket } from "./Icons/Electricity market analysis_icon.svg";
import { ReactComponent as EuLegislationIcon } from "./Icons/EU legislation_icon.svg";
import { ReactComponent as CimIcon } from "./Icons/European electricity CIM expertise_icon.svg";
import { ReactComponent as ForecastingIcon } from "./Icons/Forecasting_icon.svg";

import "../Services.less";
import "./Energy.less";

export const Energy: FunctionComponent = () => {
    return (
        <div id="energy" className="container service-container">
            <UpriseHero image="/imgs/energy.jpg" imageM="/imgs/energym.jpg" imageBrightness={80}>
                Energy & Power systems
            </UpriseHero>

            <div className="container">
                <div id="cross-zonal-capacity" className="service-section">
                    <div className="section-title">
                        <CrossBorderIcon className="icon" title="" />
                        <h2>Cross zonal capacity calculation and Power system security</h2>
                    </div>

                    <div className="section-content">
                        <p>
                            One of the main European objectives is to establish an efficient
                            internal electricity market that will allow all European citizens to
                            purchase affordable and clean electricity while maintaining security of
                            the energy supply. European climate goals and zero-carbon 2050 vision
                            drive the main changes in the European power systems and internal
                            electricity market, but also dictate the values which Uprise follows and
                            supports.
                        </p>
                        <p>
                            In order to achieve these goals, optimized planning and efficient
                            control of power systems with focus on congestion management and
                            capacity calculation is of the utmost importance for market
                            participants, as well as for system operators. One of the main tasks for
                            transmission system operators is to provide cross-zonal capacity as much
                            as possible in order to maximize electricity market welfare.
                        </p>
                        <p>
                            Achieving this, without compromising system security, is something we
                            excel at, closely following guidelines from the latest legal
                            requirements.
                        </p>

                        <Link to="/energy/cross-zonal-capacity" className="energy-link">
                            Learn more
                        </Link>
                    </div>
                </div>

                <hr className="divider" />

                <div id="electricity-market-analysis" className="service-section">
                    <div className="section-title">
                        <ElectricityMarket className="icon" title="" />
                        <h2>Electricity market analysis</h2>
                    </div>

                    <div className="section-content">
                        <p>
                            Energy transition implies the growing need for rapid and agile change of
                            the traditional electricity market while maintaining system security and
                            system operation costs. The electricity market should create incentives
                            able to tackle volatile renewable sources, integrate large numbers of
                            participants/assets, increase power system flexibility and integrate new
                            market mechanisms.
                        </p>
                        <p>
                            The ultimate European goal of achieving climate neutrality by 2050,
                            focuses ontransitioning towards greenhouse gas neutrality of the energy
                            sector. This path creates opportunities for existing and future market
                            participants and system operators in the electricity market. The twin
                            green and digital transitions foresee greater involvement of the public
                            in the electricity market, as well as an increase in the number of
                            prosumers/assets participating in the market.
                        </p>
                        <p>
                            At Uprise, not only do we closely follow the trends, but also actively
                            participate in designing solutions for the new European power system
                            vision.
                        </p>

                        <Link to="/energy/electricity-market-analysis" className="energy-link">
                            Learn more
                        </Link>
                    </div>
                </div>

                <hr className="divider" />

                <div id="eu-legislation" className="service-section">
                    <div className="section-title">
                        <EuLegislationIcon className="icon" title="" />
                        <h2>EU legislation</h2>
                    </div>

                    <div className="section-content">
                        <p>
                            EU Network codes, and consequently methodologies derived from them,
                            represent a set of rules based on the EU Commission Regulations with the
                            goal of facilitating harmonisation, integration and efficiency of the
                            European electricity market.
                        </p>
                        <p>
                            At Uprise we understand and anticipate their effect on markets and
                            implications on system security, as well as their relation to technical
                            standards,and national regulations. However, our expertise reaches
                            beyond pure theoretical understanding, as our experts have actively
                            participated in methodologies’ drafting, business solution design and
                            development of solutions.
                        </p>

                        <Link to="/energy/eu-legislation" className="energy-link">
                            Learn more
                        </Link>
                    </div>
                </div>

                <hr className="divider" />

                <div id="european-electricity-cim" className="service-section">
                    <div className="section-title">
                        <CimIcon className="icon" title="" />
                        <h2>European electricity CIM expertise</h2>
                    </div>

                    <div className="section-content">
                        <p>
                            With market liberalization the number of participants on the energy
                            market is growing, as well as the amount of data that needs to be
                            exchanged. Efficient data management is the cornerstone of energy
                            transition. In order to achieve seamless and efficient information
                            exchange at various stages, between an increasing number of companies –
                            TSOs, DSOs, energy producers and consumers and aggregators -
                            standardised and commonly agreed upon data formats are used across
                            Europe.
                        </p>
                        <p>
                            At Uprise, we are well aware of the data formats as we have been using
                            them and participated in their creation for our multiple products, as
                            well as exchanging principles via dedicated communication protocols like
                            ECP/EDX and EccoSP.
                        </p>

                        <Link to="/energy/european-electricity-cim" className="energy-link">
                            Learn more
                        </Link>
                    </div>
                </div>

                <hr className="divider" />

                <div id="forecasting" className="service-section">
                    <div className="section-title">
                        <ForecastingIcon className="icon" title="" />
                        <h2>Forecasting</h2>
                    </div>

                    <div className="section-content">
                        <p>
                            The ability to make predictions is an important aspect of life.
                            Logically, it translates into the realm of electrical power engineering
                            and directly correlates with profits. With more and more intermittent
                            energy sources in the generation mix, as well as electric vehicles and
                            power walls, it is becoming increasingly more complex to predict
                            behaviour of people and assets and, consequently, variables on the
                            electricity market.
                        </p>
                        <p>
                            At Uprise, we aim at creating accurate and robust multifunctional
                            prediction algorithms based on the combination of machine learning and
                            business knowledge, adaptive to each type of market participant.
                        </p>

                        <Link to="/energy/forecasting" className="energy-link">
                            Learn more
                        </Link>
                    </div>
                </div>

                <hr className="divider" />
                <div className="tech-container">
                    <UpriseTechnologies
                        title="Technologies we use"
                        items={techsAndTools.technologies}
                    />
                </div>
                <hr className="divider" />
                <div className="table-container">
                    <WhyUpriseTable />
                </div>
                <hr className="divider" />
                <div className="tech-container">
                    <UpriseTechnologies title="Tools we use" items={techsAndTools.tools} />
                </div>
            </div>
        </div>
    );
};
