import { gql } from '@apollo/client';

export const POSTS_QUERY = gql`
  query Articles {
    articles(sort: "createdAt:desc") {
      data {
        attributes {
          slug
          title
          description
          createdAt
          tag
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const LANDING_POSTS_QUERY = gql`
  query Articles {
    articles(sort: "createdAt:desc", pagination: { start: 0, limit: 3 }) {
      data {
        attributes {
          slug
          title
          createdAt
          tag
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;