import { FunctionComponent } from "react";
import { Carousel } from "react-responsive-carousel";
import { UpriseHero } from "../../../../components/UpriseHero/UpriseHero";
import { ReactComponent as LeftArrow } from "./left arrow.svg";
import { ReactComponent as RightArrow } from "./right arrow.svg";

import "../../Services.less";
import "./SubSections.less";

export const CrossZonalCapacity: FunctionComponent = () => {
    return (
        <div id="cross-zonal-capacity" className="energy-container">
            <UpriseHero image="/imgs/x_border_cc.jpg" half={true} imageBrightness={100}>
                CROSS ZONAL CAPACITY CALCULATION <br /> and <br /> POWER SYSTEM SECURITY
            </UpriseHero>

            <div className="energy-section">
                <p>
                    One of the main European objectives is to establish an efficient internal
                    electricity market that will allow all European citizens to purchase affordable
                    and clean electricity while maintaining security of the energy supply. European
                    climate goals and zero-carbon 2050 vision drive the main changes in the European
                    power systems and internal electricity market, but also dictate the values which
                    Uprise follows and supports.
                </p>
                <p>
                    In order to achieve these goals, optimized planning and efficient control of
                    power systems with focus on congestion management and capacity calculation is of
                    the utmost importance for market participants, as well as for system operators.
                    One of the main tasks for transmission system operators is to provide
                    cross-zonal capacity as much as possible in order to maximize electricity market
                    welfare.
                </p>
                <p>
                    Achieving this, without compromising system security, is something we excel at,
                    closely following guidelines from the latest legal requirements.
                </p>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>AREAS OF EXPERTISE</h3>

                <div className="expertise">
                    <div className="expertise-item">
                        <span className="ordinal">1</span>
                        <div className="expertise-content">
                            <div>
                                <b>Security analysis and Congestion management</b>
                            </div>
                            <p>
                                Sensitivity and contingency analysis represent the main instrument
                                to perform flow-based capacity calculation and security analysis of
                                electrical power systems. At Uprise, we know how to efficiently
                                implement computation of PTDFs, PSDFs, load and loop flows on a
                                large scale, with multiple remedial action combinations, taking into
                                account time and resource constraints.
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">2</span>
                        <div className="expertise-content">
                            <div>
                                <b>Capacity calculation</b>
                            </div>
                            <p>
                                At Uprise we are deeply involved with both main methodologies for
                                calculating the cross zonal capacities - Flowbased and NTC-based
                                concept.
                            </p>
                            <p>
                                We know how to implement complex data processing and calculation
                                tools which utilize the know-how on power system modelling
                                algorithms, all with the main objective to ensure efficient capacity
                                calculation process.
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">3</span>
                        <div className="expertise-content">
                            <div>
                                <b>Internal capacity validation</b>
                            </div>
                            <p>
                                So as not to endanger internal operational security, each TSO has a
                                right to review and influencecross-zonal capacities which were
                                calculated during the capacity calculation process. At Uprise, we
                                know how to implement flow-based cross-zonal capacity validation,
                                ensuring the best possible market position, as well as to address
                                the demanding requirements related to minimum RAM, as specified in
                                the Clean Energy Package.
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">4</span>
                        <div className="expertise-content">
                            <div>
                                <b>Cost sharing</b>
                            </div>
                            <p>
                                Implementation and simulation of complex cost-sharing methodologies
                                is in scope of our work and expertise, following the provisions of
                                relevant European methodologies but also specific requirements from
                                our Partners.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>PRODUCTS AND CASE STUDIES</h3>

                <div className="energy-carousel">
                    <Carousel
                        autoPlay
                        infiniteLoop
                        showThumbs={false}
                        showStatus={false}
                        interval={5000}
                        renderArrowPrev={(clickHandler) => (
                            <LeftArrow
                                onClick={clickHandler}
                                aria-label="previous slide / item"
                                className="arrow left"
                                title=""
                            />
                        )}
                        renderArrowNext={(clickHandler) => (
                            <RightArrow
                                onClick={clickHandler}
                                aria-label="next slide / item"
                                className="arrow right"
                                title=""
                            />
                        )}
                    >
                        <div className="carousel-panel">
                            <h3>Capacity calculation data processor</h3>
                            <p>
                                Tool developed for the purpose of CORE CCR capacity calculation
                                covering calculations and processing of data before mathematical
                                optimization, as well as interpretation and reporting of
                                optimization results defining optimal usage of available remedial
                                actions and their effect.
                            </p>
                        </div>
                        <div className="carousel-panel">
                            <h3>Capacity validation tool</h3>
                            <p>
                                Web-based tool that provides the option of setting validation
                                parameters and reviewing and affecting cross-zonal capacities which
                                were calculated during the capacity calculation process following
                                capacity validation principles.
                            </p>
                        </div>
                        <div className="carousel-panel">
                            <h3>NTC calculation tool</h3>
                            <p>
                                Intraday TTC selection and NTC calculation tool based on the
                                NTC-based capacity calculation method with minimum capacity
                                adjustment functionality for North Italy CCR.
                            </p>
                        </div>
                    </Carousel>
                </div>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>KEYWORDS</h3>
                <br />
                <div className="keywords">CNEC, PTDF, PSDF, DC, NTC, TTC, RAM, GSK, LSK</div>
            </div>
        </div>
    );
};
