import { combineReducers, createStore } from "redux";
import { PageState, pageStateReducer as pageState } from "./pageStateReducer";

export interface AppState {
    pageState: PageState;
}

export default function congfigureStore(initialState?: AppState) {
    const rootReducer = combineReducers<AppState>({
        pageState
    });

    const enhancers = [];
    const windowIfDefined = typeof window === "undefined" ? null : (window as any);
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(rootReducer, initialState, ...enhancers);
}