import { Reducer } from "redux";
import { CLOSE_MENU, PageStateActionType, TOGGLE_MENU } from "../actions/pageActions";

export interface PageState {
    menuOpen: boolean;
} 

let initialState: PageState = {
    menuOpen: false
}

export const pageStateReducer: Reducer<PageState> = (
    state: PageState = initialState,
    action: PageStateActionType
): PageState => {
    switch(action.type) {
        case TOGGLE_MENU:
            return { ...state, menuOpen: !state.menuOpen }
        case CLOSE_MENU:
            return {...state, menuOpen: false }
        default:
            return state;
    }
}