import { gql } from '@apollo/client';

export const ARTICLE_QUERY = gql`
  query Articles($slug: String!) {
    articles(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          slug
          title
          tag
          createdAt
          content
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
