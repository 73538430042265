import { FunctionComponent } from 'react';
import { Tagline } from '../../../components/Tagline/Tagline';

import "../Legal.less";

export const LegalInformation: FunctionComponent = () => {
    return <div id="legal" className="legal-section">
        <div className="header">
            <Tagline>
                Legal Information
            </Tagline>
        </div>
        <div className="content">
            <div>Uprise is a limited company dedicated to information technology services.</div>
            <div>The company is registered with the Commercial Court in Zagreb under registration number MBS 081125428.</div>
            <div>Base capital of the company of 20.000,00 has been paid in full.</div>
            <div>Members of the Management Board: Dino Mileta.</div>
            <br />
            <br />

            <p><b>Address: </b>Trg Drage Iblera 10</p>
            <p><b>Email: </b><a href="mailto:info@uprise.hr">info@uprise.hr</a></p>
            <p><b>Phone: </b>(+385)954433487.</p>
            <p><b>Bank account: </b>3024020061100846959</p>
            <p><b>IBAN: </b>HR3024020061100846959</p>
            <p><b>Name of Bank: </b>Erste & Steiermarkische Bank d.d</p>
        </div>
    </div>
}