import { FunctionComponent } from "react";
import { UpriseHero } from "../../../components/UpriseHero/UpriseHero";
import { Tagline } from "../../../components/Tagline/Tagline";
import UpriseTechnologies from "../../../components/UpriseTechnologies/UpriseTechnologies";
import techsAndTools from "../../../components/UpriseTechnologies/tech_and_tools.json";
import { WhyUpriseTable } from "../../../components/WhyUpriseTable/WhyUpriseTable";
import { ReactComponent as DevelopmentIcon } from "./Icons/Development_icon.svg";
import { ReactComponent as QaIcon } from "./Icons/QA_icon.svg";
import { ReactComponent as MaintenanceIcon } from "./Icons/Maintenance_icon.svg";
import { ReactComponent as IntegrationIcon } from "./Icons/Integration_icon.svg";
import { ReactComponent as TransformationIcon } from "./Icons/Transformation_icon.svg";
import "../Services.less";
import "./Software.less";

export const Software: FunctionComponent = () => {
    return (
        <div id="software" className="container service-container">
            <UpriseHero image="/imgs/software.jpg" imageM="/imgs/softwarem.jpg" imageBrightness={80}>
                Software Engineering
            </UpriseHero>

            <div className="container">
                <div id="development" className="service-section">
                    <div className="section-title">
                        <DevelopmentIcon className="icon" title="" />
                        <h2>Software development</h2>
                    </div>
                    <div className="section-content">
                        <p>
                            We develop and deliver cutting-edge software. The team is technically
                            skilled, our developers simply know their stack. The customer is at the
                            center and that is not an empty phrase.
                        </p>
                        <p>
                            Projects are approached individually from an idea to the complete
                            product. The client is always included. In an agile environment creative
                            minds are put together to produce top-notch scalable software that
                            achieves business goals.
                        </p>
                        <p>
                            We have delivered software for a range of applications, from energy and
                            fintech to art and education. That means we are versatile and adaptable.
                            And that in turn means our clients can trust us. The approach is
                            test-driven, communication is constant on all project levels, and the
                            final products match the level of quality our clients expect from us.
                            Fulfilling your business needs is what we are about.
                        </p>
                    </div>
                    <Tagline className="section-tagline">We care about your results.</Tagline>
                </div>
                <hr className="divider" />
                <div id="qa" className="service-section">
                    <div className="section-title">
                        <QaIcon className="icon" title="" />
                        <h2>Quality assurance & Software testing</h2>
                    </div>
                    <div className="section-content">
                        <p>
                            Impeccable software quality and efficiency is a must for us. That is why
                            we plan with care and meticulously implement the procedures required to
                            achieve that goal. Our processes and practices are never random, they
                            are well defined and ISO9001 & 27001 certified.
                        </p>
                        <p>
                            Our experienced QA team uses the latest tools in testing individual
                            components throughout the process of continuous delivery. Once finished,
                            the software goes through various other testing processes to ensure that
                            the system is set up correctly and will operate as expected before it
                            ships to site, that the control and safety systems are fully operational
                            and that the end-to-end business flow runs smoothly.
                        </p>
                    </div>

                    <Tagline className="section-tagline">
                        Quality matters. That is why we leave no piece of code unturned and no bug
                        ignored.
                    </Tagline>
                </div>
                <hr className="divider" />
                <div id="maintenance" className="service-section">
                    <div className="section-title">
                        <MaintenanceIcon className="icon" title="" />
                        <h2>Maintenance & Support</h2>
                    </div>
                    <div className="section-content">
                        <p>
                            The process of developing or buying software and introducing it into
                            your IT infrastructure represents the first stage. As your business
                            evolves and your system components interact, there are factors to
                            consider. You don’t want your software to crash or become outdated,
                            right? That is why we take a proactive stance and offer round-the-clock
                            maintenance and support customized to your business. We ensure that the
                            product continues to perform according to specifications. Our services
                            include incident resolution, problem analysis, software maintenance and
                            enhancement.
                        </p>
                        <p>
                            We face the changing technical and business environment together, to
                            help you and your software adapt and excel.
                        </p>
                    </div>
                    <Tagline className="section-tagline">We stay with you all the way.</Tagline>
                </div>
                <hr className="divider" />
                <div id="integration" className="service-section">
                    <div className="section-title">
                        <IntegrationIcon className="icon" title="" />
                        <h2>System integration</h2>
                    </div>
                    <div className="section-content">
                        <p>
                            Projects grow and so does accompanying software. When software
                            subsystems, modules, and programs exist as separate systems, this
                            results in increased expenses on data and information transfer, as well
                            as a fragmented view of the company and its activities. This in turn
                            complicates the operational control of activities and leads to data loss
                            and inconsistencies.
                        </p>
                        <p>
                            Software integration is the process of bringing together various types
                            of software subsystems so that they create a unified single system. Our
                            experts provide a thorough assessment of the key software assets and
                            optimize your system by integrating the existing software with the new.
                        </p>

                        <p>Our system integration practices bring the following benefits:</p>
                        <ul>
                            <li>Easier data accessibility across platforms</li>
                            <li>Better understanding of key data</li>
                            <li>Reduction of duplicate or redundant tasks</li>
                            <li>Data sync across all applications and for all users</li>
                            <li>More effective collaboration across all levels</li>
                            <li>Flexibility of your information system.</li>
                        </ul>

                        <p>
                            The key is in successfully integrating all subsystems into a single,
                            fully functioning system. That makes it easy to keep track of data and
                            for all elements to function together and supplement each other. The
                            result is better decision-making that reduces operational costs and
                            ultimately enables business growth.
                        </p>
                    </div>

                    <Tagline className="section-tagline">
                        Make system integration a priority, not an afterthought.
                    </Tagline>
                </div>

                <hr className="divider" />

                <div id="transformation" className="service-section">
                    <div className="section-title">
                        <TransformationIcon className="icon" title="" />
                        <h2>Digital transformation</h2>
                    </div>
                    <div className="section-content">
                        <p>
                            All industries are becoming increasingly more digital so for any
                            business to survive these ever-changing circumstances, it is necessary
                            to re-invent your practices. Legacy technologies can’t help you follow
                            these new trends, they can’t adapt to your needs and it is costly to
                            maintain them.
                        </p>
                        <p>
                            Even though technology and its benefits are constantly reshaping our
                            reality, one thing remains constant- companies want to grow and thrive.
                            We can help you keep up with new tech solutions and use them to your
                            advantage.
                        </p>
                        <p>
                            Ditching analogue and embracing digital practices leads to faster, more
                            efficient employee communication, better audience reach, reduced cost of
                            business, higher customer accessibility, easier data collection and many
                            other benefits. The goal is to connect your employees, data and
                            customers.
                        </p>
                    </div>

                    <Tagline className="section-tagline">
                        We build roadmaps to painless digital transformations and help you optimize
                        your business.
                    </Tagline>
                </div>

                <hr className="divider" />

                <div className="tech-container">
                    <UpriseTechnologies
                        title="Technologies we use"
                        items={techsAndTools.technologies}
                    />
                </div>
                <hr className="divider" />
                <div className="table-container">
                    <WhyUpriseTable />
                </div>
                <hr className="divider" />
                <div className="tech-container">
                    <UpriseTechnologies title="Tools we use" items={techsAndTools.tools} />
                </div>
            </div>
        </div>
    );
};
