import { FunctionComponent } from "react";
import { Tagline, TaglineProps } from "../Tagline/Tagline";
import './UpriseHero.less';

interface HeroProps extends TaglineProps {
    image: string,
    imageM?: string,
    imageBrightness?: number,
    learnMore?: string,
    half?: boolean
}

export const UpriseHero:FunctionComponent<HeroProps> = ({className, learnMore, image, imageM, children, imageBrightness, half = false, ...props}) => {
    return <div {...props} className={`${className ? className: ''} hero-container ${half ? 'half': ''}`}>
        <picture>
            <source media="(min-width: 900px)" srcSet={image} />
            { imageM && <source media="(max-width: 899px)" srcSet={imageM} />}
            <img className="hero-image" src={image} style={{filter: `brightness(${imageBrightness}%)`}} alt="" />
        </picture>
        <Tagline learnMore={learnMore}>{children}</Tagline>
    </div>
}