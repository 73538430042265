import { Carousel } from "react-responsive-carousel";
import "./Quotes.less";

export interface IQuote {
    quote: string;
    author: string;
    reference: string;
}

export interface IQuotesProps {
    quotes: IQuote[];
}

export const Quotes = ({ quotes }: IQuotesProps) => {
    return (
        <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            swipeScrollTolerance={20}
            preventMovementUntilSwipeScrollTolerance={true}
            showArrows={false}
            className="uprise-carousel quotes"
        >
            {quotes.map((quote, index) => {
                return (
                    <div key={index}>
                        <p className="quote">{quote.quote}</p>
                        <p className="author">{quote.author}</p>
                        <p className="reference">{quote.reference}</p>
                    </div>
                );
            })}
        </Carousel>
    );
};
