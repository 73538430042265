import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ToggleMenu } from "../../actions/pageActions";
import { AppState } from "../../store/configureStore";

import './MenuButton.less';

function MenuButton() {
    const open = useSelector<AppState>(state => state.pageState.menuOpen);
    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(ToggleMenu());
    }

    return (
        <div id="menu-button" className={open ? "change": ""} onClick={toggle}>
            <div id="bar1" className="bar"></div>
            <div id="bar2" className="bar"></div>
            <div id="bar3" className="bar"></div>
        </div>
    );
}

export default MenuButton;