import { FunctionComponent } from "react";
import { UpriseHero } from "../../../components/UpriseHero/UpriseHero";
import { Tagline } from "../../../components/Tagline/Tagline";
import UpriseTechnologies from "../../../components/UpriseTechnologies/UpriseTechnologies";
import techsAndTools from "../../../components/UpriseTechnologies/tech_and_tools.json";
import { WhyUpriseTable } from "../../../components/WhyUpriseTable/WhyUpriseTable";
import { ReactComponent as OptimizationIcon } from "./Icons/Optimization_icon.svg";
import { ReactComponent as MlIcon } from "./Icons/ML_icon.svg";
import "../Services.less";

export const OptimizationAndML: FunctionComponent = () => {
    return (
        <div id="optimization-and-ml" className="container service-container">
            <UpriseHero
                image="/imgs/optimization.jpg"
                imageM="/imgs/optimizationm.jpg"
                imageBrightness={80}
            >
                Optimization & Machine learning
            </UpriseHero>

            <div className="container">
                <div id="optimization" className="service-section">
                    <div className="section-title">
                        <OptimizationIcon className="icon" title="" />
                        <h2>Optimization</h2>
                    </div>
                    <div className="section-content">
                        <p>
                            There may come a time when your business stumbles upon a problem without
                            a clear solution in sight. Our experts can help you determine all the
                            variables and your goals to come up with the ideal solution. Sounds
                            easy, right? There is a sophisticated optimization solver that helps us
                            do that - Gurobi Optimizer. It can tackle almost any type of business
                            problem, no matter the scope or complexity. Many leading companies have
                            already implemented optimization across a broad spectrum of business
                            functions including production, distribution, purchasing, finance,
                            capital investment, and human resources – and enabled automated, optimal
                            decision making.
                        </p>
                    </div>
                    <Tagline className="section-tagline">Save time, make better decisions.</Tagline>
                </div>

                <hr className="divider" />

                <div id="ml" className="service-section">
                    <div className="section-title">
                        <MlIcon className="icon" title="" />
                        <h2>Machine learning</h2>
                    </div>
                    <div className="section-content">
                        <p>
                            We use algorithms to create models that have a predictive capacity, so
                            there is less need for human intervention. These data driven predictions
                            work like magic and enable you to be a better decision-maker, developing
                            a deeper understanding of your customers along the way. By relying on
                            strategic decision-making, you will boost your business performance.
                        </p>
                        <p>
                            With our deep learning solutions, you will be prepared to face the
                            ever-changing business climate. Whether you can benefit from future
                            forecasting, spam filtering, product recommendations, anomaly detection
                            or something else, we deliver a customized solution to cover your
                            business needs.
                        </p>
                        <p>
                            This process includes gathering and filtering relevant data, extracting
                            insights needed to build and test models until they are accurate and
                            effective. Once that is achieved, the models are deployed and you can
                            start scaling up your business.
                        </p>
                    </div>
                    <Tagline className="section-tagline">Use technology to your advantage.</Tagline>
                </div>

                <hr className="divider" />

                <div className="tech-container">
                    <UpriseTechnologies
                        title="Technologies we use"
                        items={techsAndTools.technologies}
                    />
                </div>
                <hr className="divider" />
                <div className="table-container">
                    <WhyUpriseTable />
                </div>
                <hr className="divider" />
                <div className="tech-container">
                    <UpriseTechnologies title="Tools we use" items={techsAndTools.tools} />
                </div>
            </div>
        </div>
    );
};
