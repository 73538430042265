import { FunctionComponent } from "react";
import { Carousel } from "react-responsive-carousel";
import { UpriseHero } from "../../../../components/UpriseHero/UpriseHero";
import { ReactComponent as LeftArrow } from "./left arrow.svg";
import { ReactComponent as RightArrow } from "./right arrow.svg";

import "../../Services.less";
import "./SubSections.less";

export const EuropeanElectricityCIM: FunctionComponent = () => {
    return (
        <div id="cim" className="energy-container">
            <UpriseHero image="/imgs/cim.jpg" half={true} imageBrightness={100}>
                European electricity CIM expertise
            </UpriseHero>

            <div className="energy-section">
                <p>
                    With market liberalization the number of participants on the energy market is
                    growing, as well as the amount of data that needs to be exchanged. Efficient
                    data management is the cornerstone of energy transition. In order to achieve
                    seamless and efficient information exchange at various stages, between an
                    increasing number of companies – TSOs, DSOs, energy producers and consumers and
                    aggregators - standardised and commonly agreed upon data formats are used across
                    Europe.
                </p>
                <p>
                    At Uprise, we are well aware of the data formats as we have been using them and
                    participated in their creation for our multiple products, as well as exchanging
                    principles via dedicated communication protocols like ECP/EDX and EccoSP.
                </p>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>AREAS OF EXPERTISE</h3>

                <div className="expertise">
                    <div className="expertise-item">
                        <span className="ordinal">1</span>
                        <div className="expertise-content">
                            <div>
                                <b>Capacity calculation files</b>
                            </div>
                            <p>
                                While developing products for capacity calculation purposes, we have
                                gained vast knowledge on data formats used to exchange data related
                                to bidding zone market positions, network elements and remedial
                                actions’ properties, mainly in the XML format.
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">2</span>
                        <div className="expertise-content">
                            <div>
                                <b>Resource capacity registry tool files</b>
                            </div>
                            <p>
                                As part of supporting activities provided in order to implement
                                capacity registry tool for cross-border participation, our experts
                                provided support for appropriate schema design in line with IEC
                                standards and using unified modelling language (UML).
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">3</span>
                        <div className="expertise-content">
                            <div>
                                <b>UCTE and CGMES</b>
                            </div>
                            <p>
                                UCTE files manipulation represents the basis of almost every
                                operation process in the TSO world. At Uprise we have a deep
                                understanding of the specifics and limitations of the UCTE format
                                from both business and IT perspectives.
                            </p>
                            <p>
                                CGMES, on the other hand, represents a novel format for data
                                exchange based on the IEC CIM standards that will eventually fully
                                support relevant power system analyses. Uprise experts supported
                                CGMES implementation and have full theoretical understanding of the
                                concept.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>PRODUCTS AND CASE STUDIES</h3>

                <div className="energy-carousel">
                    <Carousel
                        autoPlay
                        infiniteLoop
                        showThumbs={false}
                        showStatus={false}
                        interval={5000}
                        renderArrowPrev={(clickHandler) => (
                            <LeftArrow
                                onClick={clickHandler}
                                aria-label="previous slide / item"
                                className="arrow left"
                                title=""
                            />
                        )}
                        renderArrowNext={(clickHandler) => (
                            <RightArrow
                                onClick={clickHandler}
                                aria-label="next slide / item"
                                className="arrow right"
                                title=""
                            />
                        )}
                    >
                        <div className="carousel-panel">
                            <h3>Capacity calculations</h3>
                            <p>
                                Usage of capacity calculation related file formats for multiple
                                products developed for our customers.
                            </p>
                        </div>
                        <div className="carousel-panel">
                            <h3>Capacity registry tool</h3>
                            <p>
                                Project consultancy and support for setting up a central registry of
                                capacity providers participating in foreign capacity mechanisms.
                            </p>
                        </div>
                    </Carousel>
                </div>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>KEYWORDS</h3>
                <br />
                <div className="keywords">EDI, UML, CIM, ECP/EDX, OPDE</div>
            </div>
        </div>
    );
};
