import { useQuery } from '@apollo/client';
import { UpriseNewsArticle } from '../../components/UpriseNewsArticle/UpriseNewsArticle';
import { POSTS_QUERY } from '../../queries/posts'

function Posts() {
    const { loading, error, data } = useQuery(POSTS_QUERY);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            {data.articles.data && data.articles.data.map((article: any, index: number) => (
                <div key={article.attributes.slug}>
                    {index !== 0 &&
                        <hr className="divider" />
                    }
                    <UpriseNewsArticle
                        title={article.attributes.title}
                        date={new Date(article.attributes.createdAt)}
                        tag={article.attributes.tag}
                        summary={article.attributes.description}
                        link={`news/${article.attributes.slug}`}
                        image={`https://strapi.uprise.hr${article.attributes.image.data.attributes.url}`}
                    >
                    </UpriseNewsArticle>
                </div>
            ))}
        </div>
    );
}

export default Posts;