import { useEffect, useRef, useCallback, useState } from "react";
import { ReactComponent as DownArrow } from "./down_arrow.svg";

import "./VideoPlayer.less";

export interface IVideoPlayerProps {
    src: string;
    scrollToId?: string;
}

const headerOffset = 60;
export const VideoPlayer = ({ src, scrollToId }: IVideoPlayerProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [scrollAtTop, setScrollAtTop] = useState(true);

    const playVideo = useCallback(async () => {
        try {
            if (videoRef.current) {
                videoRef.current.muted = true;
                await videoRef.current.play();
            }
        } catch (e) {}
    }, []);

    useEffect(() => {
        playVideo();
    }, [playVideo]);

    useEffect(() => {
        let intervalHandle = setInterval(() => {
            if (!videoRef.current?.paused) {
                clearInterval(intervalHandle);
            }

            playVideo();
        }, 100);

        setTimeout(() => {
            clearInterval(intervalHandle);
        }, 1000);
    }, [playVideo]);

    const handleScrollTo = useCallback(() => {
        if (!scrollToId) return;

        const element = document.getElementById(scrollToId);
        if (!element) return;

        const scrollable = document.getElementById("root");
        if (!scrollable) return;

        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + scrollable.scrollTop - headerOffset;

        scrollable.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });
    }, [scrollToId]);

    useEffect(() => {
        // Find element with id root.
        const scrollable = document.getElementById("root");
        if (!scrollable) return;

        const handleScroll = () => {
            setScrollAtTop(scrollable.scrollTop < 20);
        };
        scrollable.addEventListener("scroll", handleScroll);

        // Remove the listener when the component unmounts.
        return () => {
            scrollable.removeEventListener("scroll", handleScroll);
        };
    }, [setScrollAtTop]);

    return (
        <div className="video-container">
            <video ref={videoRef} className="video" autoPlay loop muted playsInline>
                <source src={src} />
            </video>
            {scrollToId && (
                <div
                    className={`scroll-overlay ${!scrollAtTop ? "disabled" : ""}`}
                    onClick={handleScrollTo}
                >
                    <DownArrow width={30} height={30} />
                </div>
            )}
        </div>
    );
};
