import { FunctionComponent, useState, useEffect } from "react";
import { ReactComponent as MessageIcon } from "./Send us a message_icon.svg";
import { Link } from "react-router-dom";
import { UpriseToast } from "../../components/UpriseToast/UpriseToast";

import { contactUs } from "../../services/contactService";
import "./ContactUs.less";

export interface ContactInfo {
    firstName: string;
    lastName: string;
    email: string;
    comment: string;
}

export const ContactUs: FunctionComponent = () => {
    const [info, setInfo] = useState<ContactInfo>({
        firstName: "",
        lastName: "",
        email: "",
        comment: "",
    });
    const [consent, setConsent] = useState<boolean>(false);
    const [sendingEnabled, setSendingEnabled] = useState<boolean>(false);

    const [toastVisible, setToastVisible] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>("");

    useEffect(() => {
        if (consent && info.firstName.length > 0 && info.lastName.length > 0 && info.email.length > 0) {
            setSendingEnabled(true);
        } else {
            setSendingEnabled(false);
        }
    }, [info, consent])

    function sendInfo() {
        setConsent(false);

        contactUs(info.firstName, info.lastName, info.email, info.comment).then(res => {
            if (res.ok) {
                setInfo({
                    firstName: "",
                    lastName: "",
                    email: "",
                    comment: ""
                });
                setConsent(false);

                setToastMessage("Thank you for messaging us.");
            } else {
                setToastMessage("There was a problem with the server.");
            }
        }).catch(err => {
            setToastMessage("There was a problem with the network. Check your connection.");
        }).finally(() => {
            setToastVisible(true);
            setTimeout(() => setToastVisible(false), 5000);
        })
    }

    return (
        <div id="about" className="container">
            <UpriseToast message={toastMessage} toastActive={toastVisible}/>
            <iframe
                className="google-map"
                loading="lazy"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBcUwxNecbAfACAGYrj1QuAEaDfDusipOs&q=uprise+zagreb`}
                title="Our location"
            ></iframe>

            <div id="about-container">
                <div className="greeting-container">
                    <MessageIcon id="message-icon" title="" />
                    <div className="greeting">Send us a message</div>
                </div>

                <div id="about-container-content">
                    <div id="info">
                        <h2>Uprise d.o.o</h2>
                        <p>Trg Drage Iblera 10</p>
                        <p>10 000, Zagreb, Croatia</p>
                        <p>info@uprise.hr</p>
                        <p>+385 95 4433 487</p>
                    </div>

                    <div id="contact">
                        <div id="name">
                            <div>
                                <p>
                                    <span>*</span> First Name
                                </p>
                                <input
                                    type="text"
                                    value={info.firstName}
                                    onChange={(e) =>
                                        setInfo({ ...info, firstName: e.target.value })
                                    }
                                />
                            </div>
                            <div>
                                <p>
                                    <span>*</span> Last Name
                                </p>
                                <input
                                    type="text"
                                    value={info.lastName}
                                    onChange={(e) => setInfo({ ...info, lastName: e.target.value })}
                                />
                            </div>
                        </div>

                        <div id="email">
                            <p>
                                <span>*</span> Your email
                            </p>
                            <input
                                type="email"
                                value={info.email}
                                onChange={(e) => setInfo({ ...info, email: e.target.value })}
                            />
                        </div>
                        <div>
                            <p>
                                <span>*</span> Comment
                            </p>
                            <textarea
                                placeholder="Write your message here"
                                rows={10}
                                value={info.comment}
                                onChange={(e) => setInfo({ ...info, comment: e.target.value })}
                            />
                        </div>
                        <div id="privacy-consent">
                            <input
                                type="checkbox"
                                checked={consent}
                                onChange={() => setConsent(!consent)}
                            />
                            <label className="control control-checkbox">
                                I have read and agree with Uprise's&nbsp;
                                <Link to="/privacy">privacy policy</Link>.
                            </label>
                        </div>
                        <div className="submit-container">
                            <button id="submit-message" onClick={sendInfo} disabled={!sendingEnabled}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
