import { FunctionComponent } from 'react';
import { UpriseHero } from '../../components/UpriseHero/UpriseHero'
import "./News.less";
import Posts from './Posts';

export const News: FunctionComponent = () => {
    return (

            <div id="news" className="container">
                <UpriseHero className="news-hero" image="/imgs/news.jpg" imageM="/imgs/newsm.jpg" imageBrightness={80}>
                    News & blog releases
                </UpriseHero>
                <Posts />
            </div>
    );
}

export default News;