export async function contactUs(firstName, lastName, email, comment) {
    return await fetch('api/contact', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            comment
        })
    });
}