import { UpriseFooter } from '../../components/UpriseFooter/UpriseFooter';
import UpriseHeader from '../../components/UpriseHeader/UpriseHeader';
import Menu from '../Menu/Menu';
import './Layout.less';

export const Layout = (props: any) => {
    return (
        <>
            <UpriseHeader />
            <main>
                {props.children}
            </main>
            <Menu />
            <UpriseFooter />
        </>
    );
};