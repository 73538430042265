import { FunctionComponent } from "react";
import { ReactComponent as WhyUpriseIcon } from "./Why uprise_icon.svg";
import "./WhyUpriseTable.less";
import whyUprise from "./whyUprise.json";

export const WhyUpriseTable: FunctionComponent = () => {
    return (
        <div className="why-uprise-table">
            <div className="icon-container">
                <WhyUpriseIcon className="icon" title="" />
            </div>

            <div className="table-content">
                <div className="table-header">
                    <h2>Why Uprise?</h2>
                </div>

                {whyUprise.map((reason) => {
                    return (
                        <div key={reason.title} className="table-row">
                            <div className="row-title">{reason.title}</div>
                            <div className="row-content">{reason.description}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
