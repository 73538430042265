import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "./logo_footer.svg";
import { ReactComponent as FacebookLogo } from "./Facebook_icon.svg";
import { ReactComponent as InstagramLogo } from "./Instagram_icon.svg";
import { ReactComponent as LinkedinLogo } from "./Linkedin_icon.svg";
import { UpriseToast } from "../UpriseToast/UpriseToast";
import content from "./footerContent.json";
import { newsletterSubscription } from "../../services/newsletterService";
import "./UpriseFooter.less";

interface FooterItem {
    text?: string;
    link?: string;
    img?: string;
}

function renderFooterContent(item: FooterItem) {
    if (item.link) {
        return <Link key={item.link} to={item.link}>{item.text}</Link>;
    }
    else if (item.text) {
        return <p key={item.text}>{item.text}</p>;
    }
    else if (item.img) {
        return <img key={item.img} src={item.img} />
    }
}

export const UpriseFooter: FunctionComponent = () => {
    const [email, setEmail] = useState("");
    const [toastVisible, setToastVisible] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>("");

    function confirmSubscription() {
        // TODO: Maybe needs validation!
        newsletterSubscription(email).then(res => {
            if (res.ok) {
                setToastMessage("Thank you for your subscription.");
            } else {
                setToastMessage("There was a problem with the server.");
            }

            setEmail("");
        }).catch(err => {
            setToastMessage("There was a problem with the network. Check your connection.");
        }).finally(() => {
            setToastVisible(true);
            setTimeout(() => setToastVisible(false), 5000);
        });
    }

    return (
        <footer>
            <UpriseToast message={toastMessage} toastActive={toastVisible} />

            <div className="footer-links-container">
                {content.map((container, i) => (
                    <div key={"footer-container-" + i} className="footer-links">
                        {container.map((i: FooterItem) => renderFooterContent(i))}
                    </div>
                ))}
            </div>

            <section id="socials-and-newsletter">
                <div id="socials">
                    <p>Follow us on</p>
                    <div>
                        <a href="https://www.facebook.com/uprisetech/" target="_blank" rel="noreferrer">
                            <FacebookLogo title="" />
                        </a>
                        <a href="https://www.instagram.com/uprise.tech/" target="_blank" rel="noreferrer">
                            <InstagramLogo title="" />
                        </a>
                        <a href="https://hr.linkedin.com/company/uprisetech" target="_blank" rel="noreferrer">
                            <LinkedinLogo title="" />
                        </a>
                    </div>
                </div>
                <div id="newsletter">
                    <p>Subscribe to our newsletter</p>
                    <div id="newsletter-input">
                        <input
                            type="email"
                            placeholder="Your email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button id="confirm-newsletter" onClick={confirmSubscription} disabled={email.length === 0}>Confirm</button>
                    </div>
                </div>
            </section>

            <div className="footer-legal-container">
                <Logo title="" />
                <p>© Copyright {new Date().getFullYear()} Uprise.hr</p>
                <Link to="/privacy">Privacy</Link>
                <Link to="/legal">Legal</Link>
                <Link to="/terms">Terms & Conditions</Link>
                <a href="https://uprise.hr/documents/20221020-Management-system-policy.pdf">Management System Policy</a>
                <a href="https://uprise.hr/documents/20230915-Environmental-system-policy.pdf">Environmental System Policy</a>
            </div>
        </footer>
    );
};
