import { FunctionComponent } from "react";
import { UpriseHero } from "../../../../components/UpriseHero/UpriseHero";

import "../../Services.less";
import "./SubSections.less";

export const Forecasting: FunctionComponent = () => {
    return (
        <div id="forecasting" className="energy-container">
            <UpriseHero image="/imgs/forecasting.jpg" half={true} imageBrightness={100}>
                Forecasting
            </UpriseHero>

            <div className="energy-section">
                <p>
                    The ability to make predictions is an important aspect of life. Logically, it
                    translates into the realm of electrical power engineering and directly
                    correlates with profits. With more and more intermittent energy sources in the
                    generation mix, as well as electric vehicles and power walls, it is becoming
                    increasingly more complex to predict behaviour of people and assets and,
                    consequently, variables on the electricity market.
                </p>
                <p>
                    At Uprise, we aim at creating accurate and robust multifunctional prediction
                    algorithms based on the combination of machine learning and business knowledge,
                    adaptive to each type of market participant.
                </p>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>AREAS OF EXPERTISE</h3>

                <div className="expertise">
                    <div className="expertise-item">
                        <span className="ordinal">1</span>
                        <div className="expertise-content">
                            <div>
                                <b>Load forecasting</b>
                            </div>
                            <p>
                                Focused on short term predictions, at Uprise we have developed an
                                electrical power system load prediction module based on artificial
                                neural networks. Our experts have a deep understanding of the
                                factors that determine load magnitude, as well as of the methods of
                                their incorporation into prediction algorithms in order to get the
                                best results.
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">2</span>
                        <div className="expertise-content">
                            <div>
                                <b>Solar power plants generation forecasting</b>
                            </div>
                            <p>
                                With the increased percentage of solar power plants generation in
                                the energy mix, it has become crucial to successfully predict their
                                production even in the medium term in order to achieve the most
                                efficient system operation. From the power plant owner perspective,
                                if a solar power plant is paired with energy storage, the accuracy
                                of power plant generation prediction becomes closely correlated with
                                the profit.
                            </p>
                        </div>
                    </div>

                    <div className="expertise-item">
                        <span className="ordinal">3</span>
                        <div className="expertise-content">
                            <div>
                                <b>Electricity price forecasting</b>
                            </div>
                            <p>
                                Electricity price forecasting can be considered the most complex
                                type of forecasting. It is, however, the ultimate tool for
                                decision-making in the liberated electricity market. At Uprise we
                                have a deep understanding of different electricity price forming
                                mechanisms, as well as of utilizing different technologies and
                                algorithms to predict electricity price.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>PRODUCTS AND CASE STUDIES</h3>

                <div className="energy-carousel">
                    <div className="carousel-panel">
                        <h3>Load forecasting module</h3>
                        <p>
                            Power system hourly load prediction model based on the artificial neural
                            network and 2-D periodic radian time space transformation.
                        </p>
                    </div>
                </div>
            </div>

            <hr className="divider" />

            <div className="energy-section">
                <h3>KEYWORDS</h3>
                <br />
                <div className="keywords">
                    Tensorflow, Keras, Neural network, 2D radian time space
                </div>
            </div>
        </div>
    );
};
