import { FunctionComponent } from "react";
import { HashLink as Link } from "react-router-hash-link";

import dateformat from "dateformat";
import "./UpriseCard.less";

export interface CardProps {
    image: string;
    title: string;
    titleLink?: string;
    children?: string;
    className?: string;
    date?: Date;
    tag?: string;
    article?: string;
    color?: string;
}

export const UpriseCard: FunctionComponent<CardProps> = ({
    image,
    title,
    titleLink,
    children,
    className,
    date,
    tag,
    article,
    color,
    ...props
}) => {
    return (
        <div {...props} className={`${className ? className : ""} card ${color}`}>
            <img className="card-image" src={image} alt="" />
            {(date || tag) && (
                <span>
                    {date && dateformat(date, "mmm dd, yyyy")}
                    {date && tag && " | "}
                    {tag}
                </span>
            )}
            {titleLink && (
                <Link className="title-link" to={titleLink}>
                    <h3>{title}</h3>
                </Link>
            )}
            {!titleLink && <h3>{title}</h3>}
            <p>{children}</p>
            {article && (
                <>
                    <div className="spacer"></div>
                    <Link className="article-link" to={article}>
                        Read article
                    </Link>
                </>
            )}
        </div>
    );
};
